<template>
    <div class="flex center" style="width:250px">
        <div class="ivu-input-group-prepend flex center"><slot name="prepend"></slot></div>
        <div class="flex center input-price-range">
            <input
                class="price-input"
                autocomplete="off"
                spellcheck="false"
                @input="(event)=>change(event,0)"
                ref="precisionCursor1"
                @change="(event)=>change(event,0)"
                :value="priceRange[0]"
                placeholder="最低价格">
            <span>~</span>
            <input
                class="price-input"
                autocomplete="off"
                spellcheck="false"
                @input="(event)=>change(event,1)"
                ref="precisionCursor2"
                @change="(event)=>change(event,1)"
                :value="priceRange[1]"
                placeholder="最高价格">
        </div>
    </div>
</template>

<script>
const dateSorter = (a, b) => {
    if (!a || !b) return 0;
    return a - b;
};
export default {
    name: 'InputPriceRange',
    props: {
        value: {
            type: Array,
            default: () => ['', '']
        },
    },
    data(){
        return{
            priceRange:['',''],
            min: 0,
            max: Infinity,
            activeChange: true,
            precision: 0
        }
    },
    value(newVal) {
        const minValue = newVal[0] ? Number(newVal[0]) : null;
        const maxValue = newVal[1] ? Number(newVal[1]) : null;
        this.priceRange = [minValue, maxValue].sort(dateSorter);
    },
    methods: {
        setValue (val,index) {
            // 如果 step 是小数，且没有设置 precision，是有问题的
            if (val && !isNaN(this.precision)) val = Number(Number(val).toFixed(this.precision));

            const {min, max} = this;
            // #6245
            if ( val!==null && !this.activeChange ) {
                if (val > max) {
                    val = max;
                } else if (val < min) {
                    val = min;
                }
            }

            this.$nextTick(() => {
                this.priceRange[index] = val;
                this.$emit('input', this.priceRange);
                this.$emit('on-change', this.priceRange);
            });
        },
        change (event,index) {
            if (event.type == 'input' && !this.activeChange) return;
            let val = event.target.value.trim();

            const isEmptyString = val.length === 0;
            if(isEmptyString){
                this.setValue(null);
                return;
            }
            if (event.type == 'input' && val.match(/^\-?\.?$|\.$/g)) return; // prevent fire early if decimal. If no more input the change event will fire later

            //#fixed when setting the precision val, input point cannot show problem
            const precision = this.precision;
            let cacheVal = this.priceRange[index];
            if( precision ){
                const valMatchPointArr = (val+'').match(/\./g);
                if( valMatchPointArr && valMatchPointArr.length >= 2 ){
                    cacheVal = this.priceRange[index] + '.';
                }
            }

            val = Number(val);
            if (!isNaN(val) ) {
                this.priceRange[index] = val;
                this.setValue(val);
            } else {
                event.target.value = cacheVal;
            }
        },
    }
}
</script>

<style lang="less" scoped>
@placeholder-color  : rgba(118, 135, 156, 1); // placeholder
.ivu-input-group-prepend {
    width: 80px;
    height: 32px;
    line-height: 1.5;
}
.input-price-range {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 12px;
    border: 1px solid #dcdee2;
    border-radius: 0 4px 4px 0;
    color: #333;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.price-input {
    width: 60px;
    border: none;
    outline: none;
    text-align: center;
}
.price-input::-webkit-input-placeholder {  /* WebKit browsers*/ 
    color    : @placeholder-color  !important;
}
 .price-input:-moz-input-placeholder {   /* Mozilla Firefox 4 to 18*/ 
    color    : @placeholder-color  !important;
}
.price-input::-moz-input-placeholder {  /* Mozilla Firefox 19+*/ 
    color    : @placeholder-color  !important;
}
.price-input:-ms-input-placeholder { /* Internet Explorer 10+*/ 
    color    : @placeholder-color  !important;
}

.flex{
    display: flex;
} 
.center{
    align-items: center;
}

</style>
