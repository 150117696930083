<template>
  <div class="base-form">
    <form-row v-show="showScreen" v-for="(item, index) in formList" :key="index" :label="item.label">
      <Input v-if="item.type === 'input'" v-model.trim="formData[item.model]" class="ipt" clearable :placeholder="item.placeholder||''" :style="item.style">
        <Select v-if="item.prepend && item.prepend==='select'" v-model="formData[item.model1]" slot="prepend" style="width: 80px">
            <Option v-for="item1 in item.options" :value="item1.value" :key="item1.label">{{ item1.label }}</Option>
        </Select>
        <!-- <Select v-model="select2" slot="append" style="width: 70px">
            <Option value="com">.com</Option>
            <Option value="org">.org</Option>
            <Option value="io">.io</Option>
        </Select> -->
      </Input>
      <InputNumber v-if="item.type === 'input-number'" v-model.trim="formData[item.model]" class="ipt" clearable :placeholder="item.placeholder||''" :style="item.style">
        
      </InputNumber>
      <InputPriceRange v-if="item.type === 'input-price-range'" v-model="formData[item.model]">
        <Select v-if="item.prepend && item.prepend==='select'" v-model="formData[item.model1]" slot="prepend" style="width: 80px">
          <Option v-for="item1 in item.options" :value="item1.value" :key="item1.label">{{ item1.label }}</Option>
        </Select>
      </InputPriceRange>
      <Select v-if="item.type === 'select'" v-model="formData[item.model]" class="ipt" :filterable="item.filterable" clearable>
        <Option v-for="item1 in item.options" :value="item1.value" :key="item1.label">{{ item1.label }}</Option>
      </Select>
      <RadioGroup v-if="item.type === 'radio-button'" v-model="formData[item.model]" type="button">
          <Radio v-for="item1 in item.options" :label="item1.label" :key="item1.label"></Radio>
      </RadioGroup>
      <DatePicker 
        v-if="item.type === 'datePicker-daterange'" 
        v-model="formData[item.model]" 
        separator="至" 
        format="yyyy/MM/dd" 
        type="daterange" 
        placement="bottom-end" 
        placeholder="选择日期" 
        style="width: 200px"
      ></DatePicker>
      <div v-if="item.slot">
        <slot :name='item.slot'></slot>
      </div>
    </form-row>
    <div class="footer">
      <span class="screen" @click="showScreen=!showScreen" v-if="btns.includes('screen')">
        <Icon :type="showScreen?'ios-arrow-up':'ios-arrow-down'" />
        <span>{{showScreen?'收起筛选':'展开筛选'}}</span>
      </span>
      <Button type="primary" icon="ios-search" class="btn-save" @click="search" v-if="btns.includes('search')">查询</Button>
      <Button type="primary" icon="ios-refresh" ghost class="btn-reset" @click="close" v-if="btns.includes('reset')">重置</Button>
    </div>
  </div>
</template>

<script>
import FormRow from '@/components/formRow'
import InputPriceRange from '@/components/inputPriceRange'
import { mapMutations } from 'vuex'
export default {
  name: 'baseFrom',
  props: {
    formList: {
      type: Array,
      default() {
        return []
      },
    },
    btns: {
      type: Array,
      default() {
        return ['screen','search','reset']
      }
    }
  },
  components: {
    FormRow,
    InputPriceRange
  },
  data() {
    return {
      formData: {},
      showScreen: true
    }
  },
  methods: {
    ...mapMutations(['SET_ORG_ID']),
    setData(formData){
      this.formData = formData
    },
    search() {
      if(this.formData.orgId){
        this.SET_ORG_ID(this.formData.orgId)
      }
      this.$emit('search', this.formData)
    },
    close() {
      this.formData = {}
      this.$emit('search', {},true)
    },
  },
}
</script>

<style lang="less" scoped>
.base-form {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // overflow: hidden;
  // align-items: center;
  .footer {
    margin-left: 28px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
  .ipt{
    width: 180px;
  }
  
  .btn-save {
    width: 80px;
    margin-right: 10px;
    box-shadow: none;
    font-weight: bold;
  }
  .btn-reset {
    width: 80px;
    box-shadow: none;
    font-weight: bold;
  }

  .screen {
    cursor: pointer;
    color: #FFC300;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
