<template>
  <div :class="['i-table', { 'back-stage': backStage }]" ref="iTable">
    <IviewButton
      :class="['top-right-btn', topRightBtn && topRightBtn.position && 'position']"
      v-if="topRightBtn"
      :cus-type="topRightBtn && topRightBtn.type"
      @click="$emit('on-click-top-right-btn')"
      >{{ (topRightBtn && topRightBtn.text) || topRightBtn }}</IviewButton
    >
    <Table
      v-bind="$attrs"
      :height="height"
      v-on="tableListeners"
      :border="border"
      :class="[
        'vertical-align-' + verticalAlign,
        'scroll-' + scroll,
        { 'has-border': border },
        textAlignCenter && 'text-align-center',
      ]"
      ref="table"
    >
      <template v-for="item of slotList" :slot="item.slot" slot-scope="params">
        <slot :name="item.slot" v-bind="params"></slot>
      </template>
    </Table>
    <IviewPage v-if="page" v-bind="page" v-on="pageListeners" :back-stage="backStage" :fixed="fixedPage" />
  </div>
</template>
<script>
import IviewPage from './IviewPage'
// @group iview
export default {
  name: 'IviewTable',
  components: {
    IviewPage,
  },
  props: {
    backStage: {
      type: Boolean,
    },
    // 设置默认带边框【大多数情况】
    border: {
      type: Boolean,
    },
    // 表格内容垂直方向对齐方式
    // 可选middle（不填也能达到同样效果）、top、bottom
    verticalAlign: {
      type: String,
      default: 'middle',
    },
    // 出现滚动条的方向，默认只在y轴出现
    // 可选参数：x/y/xy
    // 使y轴出现滚动条还需要在使用IviewTable组件的文件中设置.ivu-table-table的高度
    scroll: {
      type: String,
      default: 'y',
    },
    // 计算高度
    calcHeight: {
      type: String,
    },
    // 计算高度
    height: {
      type: Number,
      default: 0,
    },
    /**
     * 传入该参数时自动引用IviewPage
     * 该参数是IviewPage的props属性对象集合
     */
    page: {
      type: Object,
    },
    /**
     * 设置全部表格内容水平居中
     */
    textAlignCenter: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否固定page
     */
    fixedPage: {
      type: Boolean,
      default: true,
    },
    /**
     * 右上角按钮
     * 字符串: 直接传按钮文本，按钮样式默认为primary
     * 对象：传{text: '按钮文本', type: '按钮样式', position: true} // position表示按钮是否设置绝对定位，默认为false
     */
    topRightBtn: {
      type: [Object, String],
    },
  },
  data() {
    return {
      // 随机属性
      randomAttr: '',
      tableListeners: {},
      pageListeners: {},
    }
  },
  computed: {
    // 获取slot列表
    slotList() {
      return (
        this.$attrs &&
        this.$attrs.columns.filter((col) => {
          return col.slot
        })
      )
    },
    // height() {
    //   let ret = "";
    //   if (this.calcHeight) {
    //   }
    //   return ret;
    // }
  },
  methods: {
    // 修复出现滚顶条时表头表体不对齐的bug
    fixBody() {
      this.$nextTick(() => {
        // table body
        let table = document.querySelector(`[${this.randomAttr}] .ivu-table-body`)
        let tbody = document.querySelector(`[${this.randomAttr}] .ivu-table-body .ivu-table-tbody`)

        // 是否显示滚动条
        let showScrollY = false
        let showScrollX = false
        if (tbody.offsetHeight && table.offsetHeight) {
          showScrollY = tbody.offsetHeight >= table.offsetHeight
        }
        if (tbody.offsetWidth && table.offsetWidth) {
          showScrollX = tbody.offsetWidth >= table.offsetWidth
        }

        // 是否需要添加gutter
        let needGutter = showScrollY && showScrollX

        // table header
        let headerTr = document.querySelector(`[${this.randomAttr}] .ivu-table-header table tr`)
        let gutter = document.querySelector(`[${this.randomAttr}] .ivu-table-header table tr th.gutter`)
        if (needGutter && !gutter) {
          let th = document.createElement('th')
          th.setAttribute('class', 'gutter')
          th.width = '17px'
          headerTr.appendChild(th)
        } else if (!needGutter && gutter) {
          headerTr.removeChild(gutter)
        }
      })
    },
    // 初始化事件
    initEvent() {
      // 将IviewPage的事件分离出来
      console.log('listeners',this.$listeners)
      if (this.page) {
        let pageEvents = ['on-change', 'on-page-size-change', 'on-page-change']
        let tableListeners = { ...this.$listeners }
        let pageListeners = {}
        pageEvents.forEach((key) => {
          if (tableListeners[key]) {
            pageListeners[key] = tableListeners[key]
            delete tableListeners[key]
          }
        })
        this.tableListeners = tableListeners
        this.pageListeners = pageListeners
      } else {
        this.tableListeners = this.$listeners
      }
    },
  },
  created() {
    this.initEvent()
  },
  mounted() {
    /**
     * 给IviewTable根标签添加随机的attr
     * 使得fixBody只作用于本组件内的表格
     */
    this.randomAttr = 'i-table-' + String(Math.random()).slice(2)
    this.$refs.iTable.setAttribute(this.randomAttr, '')

    // 监听页面rezise事件
    // window.addEventListener("resize", this.fixBody);
    // this.$once("hook:beforeDestroy", () => {
    //   window.removeEventListener("resize", this.fixBody);
    // });
  },
  // 数据更新
  updated() {
    this.fixBody()
  },
}
</script>

<style lang="less" scoped>
@t-border-color: #e9f0ec;
@t-bg: #bbe8ce;
// @t-bg-odd: fade(rgba(31, 51, 77, 0.5), 12%);
// @t-bg-even: fade(rgba(31, 51, 77, 0.2), 5%);
@t-bg-odd: #fff;
@t-bg-even: #fafafa;
@t-header-bg: #bbe8ce;
@t-header-text-color: #262b2d;
@t-header-text-size: 14px;
@t-body-text-color: #2c3542;
@t-body-text-size: 14px;

// 默认高度
// .table-height(calc(100vh - 350px));

.i-table {
  position: relative;
  display: flex;
  flex-direction: column;
  /deep/.ivu-table-wrapper {
    &.text-align-center {
      tr > th,
      tr > td {
        text-align: center;
        // .iview-checkbox-style;
      }
    }
    border: none;
    // 不带边框的表格
    .ivu-table {
      font-size: @t-body-text-size;
      color: @t-body-text-color;
      background: none;
      &:before {
        background: transparent;
      }
      &:after {
        background: transparent;
      }
      // 表头
      .ivu-table-header,.ivu-table-fixed-header {
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5),
        //   inset 0px 1px 3px 0px rgba(127, 127, 127, 0.5);
        box-shadow: 0px 1px 3px 0px rgba(148, 240, 251, 0.3);
        border-radius: 4px;
        // margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        table {
          width: 100px !important;
        }
        th {
          // background: @t-header-bg;
          // border-color: transparent;
          // border: none;
          color: #56725d;
          height: auto;
          padding: 8px 0;
          .ivu-table-cell {
            margin: 0 auto;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
      // .ivu-table-body 表格内容
      .ivu-table-body,.ivu-table-fixed-body {
        table {
          width: 100px;
        }
        // .show-scroll-y();
        td {
          background: transparent;
          border-color: transparent;
          color: @t-body-text-color;
          height: auto;
          padding: 12px 0;
          // &:not(:last-child) {
          //     border-right: 1px solid #292a2b;
          // }
          .ivu-table-cell {
            margin: 0 auto;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        // td背景设置透明后，再设置tr
        tr:nth-child(odd) {
          background: @t-bg-odd;
        }
        tr:nth-child(even) {
          background: @t-bg-even;
        }
      }
      // 数据为空时
      .ivu-table-tip {
        display: flex;
        align-items: center;
        table {
          width: 100px;
        }
        td {
          color: @t-header-text-color;
          border: none;
          background: transparent;
        }
      }
      // .ivu-table 带边框的表格
      &.ivu-table-border {
        &:before {
          background: @t-border-color;
        }
        &:after {
          background: @t-border-color;
        }
        th {
          border-color: @t-border-color;
        }
        td {
          border-color: @t-border-color;
        }
      }
    }
    // .ivu-table-wrapper 带边框的
    &.has-border {
      border: 1px solid @t-border-color;
      border-bottom: 0;
      border-right: 0;
      .ivu-table-header {
        th {
          // border-bottom: 1px solid @t-border-color;
          // border-right: 1px solid @t-border-color;
        }
      }
    }
    // 表格内容垂直方向的对齐方式（默认是middle，无需设置）
    &.vertical-align-top {
      .ivu-table-body {
        td {
          vertical-align: top;
        }
      }
    }
    &.vertical-align-bottom {
      .ivu-table-body {
        td {
          vertical-align: bottom;
        }
      }
    }
    // 滚动条方向
    &.scroll-x {
      /deep/.ivu-table-body,
      /deep/.ivu-table-tip {
        // .show-scroll-x;
      }
    }
    &.scroll-y {
      /deep/.ivu-table-body,
      /deep/.ivu-table-tip {
        // .show-scroll-y;
      }
    }
    &.scroll-xy {
      /deep/.ivu-table-body,
      /deep/.ivu-table-tip {
        // .show-scroll-xy;
      }
    }
  }
  .i-page {
    margin-top: 50px;
    bottom: 30px;
    right: 50px;
  }
  .top-right-btn {
    align-self: flex-end;
    margin-bottom: 10px;
    &.position {
      position: absolute;
      top: -45px;
      right: 0;
    }
  }
}
</style>
