import { render, staticRenderFns } from "./IviewPage.vue?vue&type=template&id=712dcffa&scoped=true"
import script from "./IviewPage.vue?vue&type=script&lang=js"
export * from "./IviewPage.vue?vue&type=script&lang=js"
import style0 from "./IviewPage.vue?vue&type=style&index=0&id=712dcffa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "712dcffa",
  null
  
)

export default component.exports